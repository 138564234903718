
import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, Button } from '@mui/material';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import '@google/model-viewer';
import { QRCodeSVG } from 'qrcode.react';
import CloseIcon from '@mui/icons-material/Close';
import DeviceDetector from "device-detector-js";
import OpenBrowserComp from "./OpenBrowserComp";
import ChromeDownloadComp from "./ChromeDownloadComp";

function App() {

  const [url, setURL] = useState()
  const [qrcode, setQrcode] = useState(false)
  const [browser, setBrowser] = useState(null)
  const [chromeDownload, setChromeDownload] = useState(false)


  const modelViewerRef = useRef()
  const linkRef = useRef()

  const quickLookDonateRef = useRef()

  const location = window.location.toString();
  const htmlButtonLink = new URL("./assets/ios-button/ios-shop-button-cdbf.html", location).href;
  const usdzLink = `./assets/models/cdbf.usdz#custom=${htmlButtonLink}&customHeight=medium`



  const startARhandler = () => {
    // props.startAR()

    const deviceDetector = new DeviceDetector();
    const device = deviceDetector.parse(navigator.userAgent);
    // const device = deviceDetector.parse("Mozilla/5.0 (iPhone; CPU iPhone OS 10_3 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) CriOS/56.0.2924.75 Mobile/14E5239e Safari/602.1")

    const os = device.os.name

    console.log(device)

    if (os === "iOS") {
      const anchor = linkRef.current
      const supportedAR = anchor.relList.supports("ar")

      if (supportedAR || device.client.name === "Chrome Mobile iOS") {
        console.log(os)
        const location = window.location.toString();
        const htmlButtonLink = new URL("./assets/ios-button/ios-shop-button-cdbf.html", location).href;
        // linkRef.current
        // const anchor = document.createElement("a");
        // anchor.setAttribute("href", "./assets/models/dragon.usdz#custom=https://xrshirts.s3.dualstack.us-east-1.amazonaws.com/html/ios-shop-button.html&customHeight=medium");
        anchor.setAttribute("href", `./assets/models/cdbf.usdz#custom=${htmlButtonLink}&customHeight=medium`);
        anchor.setAttribute("rel", "ar");
        anchor.appendChild(document.createElement("img"));
        anchor.click();
        if (device.client.name === "Chrome Mobile iOS") {
          setChromeDownload(true)
        }
      } else {
        setBrowser("safari")
      }
    } else {
      const canActivateAR = modelViewerRef.current.canActivateAR
      console.log({ canActivateAR })

      if (canActivateAR) {
        modelViewerRef.current.activateAR()
      } else {
        if (device.device.type === "smartphone") {
          if (device.client.name !== "Chrome Mobile") {
            setBrowser("chrome")
          }
        } else {
          setQrcode(true)
        }
      }
    }
  }

  const refreshPage = () => {
    document.location.reload(true)
  }

  const redireTo = (to) => {
    window.open(to, '_blank')
  }

  useEffect(() => {
    const location = window.location.href

    console.log(location)

    setURL(location)

    linkRef.current.addEventListener("message", function (event) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "donate_btn_clicked",
            located_at : "in_experience"
        });
        quickLookDonateRef.current.click()
        window.open("https://www.cdbf.org/donate", "_blank")
    }, false);

  }, [])


  return (
    <React.Fragment>



      <Box sx={{
        position: 'absolute',
        top: '0',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: 'white',
        transition: 'opacity 1s',
        opacity: '1',
        zIndex: '700',
        backgroundImage: `url(./assets/images/Background.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        fontWeight: 600,
      }}>
        <Box
          component='img'
          src={"./assets/images/title.svg"}
          sx={{
            fontSize: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>




        </Box>
        <Box sx={{
          height: '35vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: "relative",
          fontWeight: 600,
        }}>
          <model-viewer
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: "0",
            }}
            ref={modelViewerRef}
            id="model-viewer-demo"
            poster={`./assets/images/cdbf.png`}
            class="ft-model-viewer"
            src={`./assets/models/cdbf.glb`}
            shadow-intensity="1"
            camera-orbit="45deg 75deg 9m"
            // max-camera-orbit="auto auto 10m"
            camera-controls
            ar
            auto-rotate
            alt="A 3D model of a webar"
            ar-modes="webxr scene-viewer quick-look"
            autoplay
            loading="eager"
            ios-src={usdzLink}
          >

            <button className="Hotspot" slot="hotspot-1" data-position="0.006208293996108338m 1.7372794532068392m 2.9567420404479385m" data-normal="0.7536259782013743m 0.3706828076376028m 0.5428094887729263m" data-visibility-attribute="visible">
              <div className="HotspotAnnotation">Drummer</div>
            </button><button className="Hotspot" slot="hotspot-2" data-position="0.029055609449162745m 2.1844697697493274m 5.120147813829419m" data-normal="0.039459238621228555m 0.9976872787513258m -0.055344939291748975m" data-visibility-attribute="visible">
              <div className="HotspotAnnotation">Dragon Head</div>
            </button><button className="Hotspot" slot="hotspot-3" data-position="0.01930707188133063m 1.783153511147363m -3.6422604027421865m" data-normal="0.9236059348905737m 0.21995277709940472m 0.3139631393669785m" data-visibility-attribute="visible">
              <div className="HotspotAnnotation">Steersman</div>
            </button><button className="Hotspot" slot="hotspot-4" data-position="-0.004831299612183315m 0.737776048991579m -1.0650439540975096m" data-normal="0.05128926105192291m 0.9986838315173001m -0.0001279322978823408m" data-visibility-attribute="visible">
              <div className="HotspotAnnotation">Paddle</div>
            </button><button className="Hotspot" slot="hotspot-5" data-position="0.5206322229114068m 1.2035231407720937m -4.359135697623625m" data-normal="0.027435959048455697m 0.9246386287363902m -0.3798561232882519m" data-visibility-attribute="visible">
              <div className="HotspotAnnotation">Electic Neon Light</div>
            </button>

            <Button slot="ar-button"
              variant="outlined"
              sx={{
                display: "none",
                borderRadius: "200px",
                position: "absolute",
                top: "120px",
                right: "20px",
                gap: "10px",
                color: "#14c7b9",
                backgroundColor: "white",
                borderColor: "#14c7b9",
              }}
            >
              <ViewInArIcon />
            </Button>
            <div slot="progress-bar"></div>

          </model-viewer>
        </Box>


        <Button
          onClick={startARhandler}
          id="start-ar"
          sx={{
            color: "white",
            'fontSize': '0.8rem',
            'fontFamily': 'Roboto',
            'display': 'flex',
            'width': '70%',
            maxWidth: "350px",
            'backgroundColor': '#972113',
            'border': '3px solid #972113',
            'borderRadius': '60px',
            'padding': '4px 0',
            'gap': '10px',
            'animation': '3s infinite pop',
            fontWeight: 600,
            '&:hover': {
              background: '#972113',
            },
          }} >

          <img sx={{ padding: '0 10px', }} src={"./assets/images/ar-icon.svg"} />
          Launch experience
        </Button>

        <Button
          data-donation="true"
          data-location="splash_page"
          sx={{
            color: "black",
            'fontSize': '0.8rem',
            'fontFamily': 'Roboto',
            'display': 'flex',
            'width': '70%',
            maxWidth: "350px",
            'backgroundColor': '#FFFFFF',
            'border': '3px solid #972113',
            'borderRadius': '60px',
            'padding': '4px 0',
            'gap': '10px',
            fontWeight: 600,
            '&:hover': {
              background: 'white',
            },
          }}
          onClick={() => {
            redireTo('https://www.cdbf.org/donate')
          }}
        >
          donate to cdbf
        </Button>
        <img sx={{
          height: '5vh',
          marginBottom: "20px",
        }} src={"./assets/images/powerbylogo.svg"} />
      </Box>

      {browser && <OpenBrowserComp browser={browser} setBrowser={setBrowser} />}


      {qrcode &&
        <Box sx={{
          position: 'absolute',
          top: '0',
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#282828e0',
          transition: 'opacity 1s',
          opacity: '1',
          zIndex: '700',
          gap: "20px",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          fontWeight: 600,
        }}>

          <Box
            sx={{
              padding: '10px',
              backgroundColor: "white"
            }}
          >
            {url && <QRCodeSVG value={url} />}
          </Box>


          <Typography
            sx={{

              color: "white"
            }}
          >Scan QR Code with your mobile Phone</Typography>


          <CloseIcon
            onClick={() => {
              setQrcode(false)
            }}
            sx={{
              position: 'absolute',
              right: "0",
              top: "0",
              margin: "20px",
              width: "100px",
              height: "100px",
              color: "white"
            }}

          ></CloseIcon>
        </Box>
      }

      <Box sx={{ display: "none" }}
        component="a"
        ref={linkRef}

      ></Box>

      {chromeDownload && <ChromeDownloadComp setClose={setChromeDownload} />}

      <button
        data-donation="true"
        data-location="splash_page"
        ref={quickLookDonateRef}
        style={{
          display: "none"
        }}
      >

      </button>

    </React.Fragment>
  );
}

export default App;
