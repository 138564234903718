import { Box, Typography, Button } from '@mui/material';
import { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress';



const ChromeDownloadComp = (props) => {
    useEffect(() => {
        setTimeout(() => {
            props.setClose(false)
        }, 10000)
    }, [])
    return (
        <Box
            sx={{
                position: 'absolute',
                top: '0',
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around',
                backgroundColor: '#ffffff5c',
                zIndex: "1000"
            }}
        >


            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: "80%",
                    maxWidth: "350px",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    padding: "20px",
                    gap: "20px",
                    position: "relative"
                }}
            >
                <Box
                    component='img'
                    src="./assets/images/google-chrome-icon.svg"
                    sx={{
                        width: "60px"
                    }}
                >
                </Box>
                <CircularProgress
                    size={20}
                />
                <Typography
                    sx={{
                        textAlign: "center"
                    }}
                >Downloading File for Chrome Browser, Please wait....
                </Typography>

            </Box>
        </Box>
    )
}

export default ChromeDownloadComp