import React, { Fragment, useEffect, useState, useRef } from "react";
import { Box, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


import CopyToClipboardButton from "./CopyToClipboardButton";

function OpenBrowserComp(props) {


    const data = {
        safari: {
            icon: "./assets/images/safari-icon.svg",
            prompt: "Looks like here is some problem with your browser. Please copy the URL and open it with Safari"

        },
        chrome: {
            icon: "./assets/images/google-chrome-icon.svg",
            prompt: "Looks like here is some problem with your browser. Please copy the URL and open it with Chrome"
        }
    }

    return (

        <Fragment>
            <Box
                sx={{
                    position: "absolute",
                    height: '100%',
                    width: "100%",
                    top: '0',
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1000',
                    backgroundColor: "#2525258c",
                }}

            >

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: "80%",
                        maxWidth: "350px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        padding: "20px",
                        gap: "20px",
                        position: "relative"
                    }}
                >
                    <Box
                        component='img'
                        src={data[props.browser]?.icon}
                        sx={{
                            width: "60px"
                        }}
                    >
                    </Box>
                    <Typography
                        sx={{
                            textAlign: "center"
                        }}

                    >{data[props.browser]?.prompt}</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: "20px"
                        }}
                    >
                        <Typography>{window.location.toString()}</Typography>
                        <CopyToClipboardButton />
                    </Box>

                    <CloseIcon
                        onClick={() => {
                            props.setBrowser(null)
                        }}
                        sx={{
                            position: 'absolute',
                            right: "0",
                            top: "0",
                            margin: "-20px",
                            width: "40px",
                            height: "40px",
                            color: "#1976d2",
                            backgroundColor:"#a0a0a02e",
                            borderRadius:'100px',
                        }}

                    ></CloseIcon>
                </Box>
            </Box>
        </Fragment>
    )

}


export default OpenBrowserComp;

